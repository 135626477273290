.stacked-images-container {
    @apply relative h-64 overflow-x-auto overflow-y-hidden scrollbar-thin scrollbar-thumb-pf-gray-T1 scrollbar-track-pf-gray-T3;
    transition: all 1s ease-in-out;

}

.stacked-images {
    @apply relative w-[400px] h-64;
    transition: all 1s ease-in-out;

}

.stacked-image {
    @apply absolute left-0 top-0;
}

.stacked-image-0 { @apply rotate-0 translate-x-0 translate-y-0; }
.stacked-image-1 { @apply -rotate-5 translate-x-5 -translate-y-1; }
.stacked-image-2 { @apply rotate-5 -translate-x-2 -translate-y-2; }
.stacked-image-3 { @apply -rotate-5 -translate-x-5 translate-y-3; }
.stacked-image-4 { @apply rotate-5 translate-x-4 -translate-y-4; }

.expanded {
    @apply w-auto h-64 flex items-start;
    transition: all 1s ease-in-out;
}

.expanded.stacked-images-container {
    @apply overflow-x-auto overflow-y-hidden w-full;
    transition: all 1s ease-in-out;
}

.expanded .stacked-images {
    @apply w-auto flex-nowrap;
    transition: all 1s ease-in-out;
}

.expanded .expanded-image {
    @apply static rotate-0 translate-x-0 translate-y-0 flex-shrink-0;
}

.expanded-image {
    @apply mr-0;
}
