.tooltip-container {
  @apply relative inline-block w-full;
}

.tooltip {
  @apply max-w-[100vw] text-center absolute bg-pf-black whitespace-nowrap text-pf-white px-2 py-1 rounded text-xs z-[9999] opacity-0 transition-opacity duration-300 pointer-events-none;
  @apply overflow-visible;
}

.tooltip-container:hover .tooltip {
  @apply opacity-100;
}

.tooltip-top {
  @apply bottom-full left-1/2 -translate-x-1/2 mb-1;
}

.tooltip-bottom {
  @apply top-full left-1/2 -translate-x-1/2 mt-1;
}

.tooltip-left {
  @apply right-full top-1/2 -translate-y-1/2 mr-1;
}

.tooltip-right {
  @apply left-full top-1/2 -translate-y-1/2 ml-1;
}

.tooltip-parent {
  @apply overflow-visible;
}