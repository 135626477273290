:root {
    --background-color: "#FFFFFF";
    --panel-background-color: "#F5F5F5"
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

body,
html {
    font-family: 'DM Sans', sans-serif;
    overflow-wrap: break-word;
    word-break: break-word;
}

h1 {
    font-family: 'Poppins', sans-serif;
}

.rounded {
    border-radius: 1rem;
}

.rounded-sm {
    border-radius: 0.5rem;
}

.rounded-lg {
    border-radius: 2rem;
}

::-webkit-scrollbar-track {
    background: #ff0;
}