.radio-button-container {
  @apply inline-flex items-center cursor-pointer;
}

.radio-button {
  @apply relative inline-block w-6 h-6;
}

.radio-button-input {
  @apply opacity-0 w-0 h-0;
}

.radio-button-custom {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-pf-gray-T3 border-2 border-pf-gray-T1 transition-all duration-200 rounded-full;
}

.radio-button-custom:before {
  content: "";
  @apply absolute h-3 w-3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-pf-ember transition-all duration-200 rounded-full scale-0;
}

.radio-button-input:checked + .radio-button-custom {
  @apply border-pf-ember bg-pf-white;
}

.radio-button-input:checked + .radio-button-custom:before {
  @apply bg-pf-ember scale-100;
}

.radio-button-input:focus + .radio-button-custom {
  @apply ring-2 ring-pf-ember-T1;
}

.radio-button-label {
  @apply text-pf-gray;
}

/* Hover state */
.radio-button-container:hover .radio-button-custom {
  @apply border-pf-ember-S1;
}

/* Disabled state */
.radio-button-input:disabled + .radio-button-custom {
  @apply bg-pf-gray-T1 border-pf-gray-T2 cursor-not-allowed;
}

.radio-button-input:disabled:checked + .radio-button-custom {
  @apply bg-pf-gray-T1;
}

.radio-button-input:disabled:checked + .radio-button-custom:before {
  @apply bg-pf-gray;
}

.radio-button-input:disabled ~ .radio-button-label {
  @apply text-pf-gray-T1 cursor-not-allowed;
}