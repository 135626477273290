/* Default scrollbar style */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border: 0.5px solid #ebebeb;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
  border: 1px solid #575757;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Medium screens */
@media (max-width: 1024px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 6px;
  }
}

/* Small screens */
@media (max-width: 640px) {
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar:horizontal {
    width: 4px;
  }
}

