.breadcrumbs {
  @apply flex items-center;
  overflow: visible;
}

.breadcrumbs ol {
  @apply flex items-center h-full;
}

.breadcrumb-item {
  @apply h-full relative;
}

.breadcrumb-content {
  @apply flex items-center bg-pf-primary-T3 text-pf-black pl-3 pr-1 py-1 text-sm h-full relative z-10;
  margin-left: 12px;
}

.breadcrumb-start {
  @apply bg-pf-primary h-full;
  width: 30px;
  border-top-left-radius: 25%;
  border-bottom-left-radius: 25%;
}

.breadcrumb-separator {
  @apply h-full bg-pf-primary-T3;
  width: 12px;
  border-radius: 50%;
  border-top-left-radius: -25%;
  margin-left: -6px;
}

.breadcrumb-separator__highlight {
  @apply h-full bg-pf-primary;
  width: 12px;
  border-radius: 50%;
  border-top-left-radius: -25%;
  margin-left: -6px;
}

.breadcrumb-label {
  @apply py-2 px-1 hover:text-pf-primary-S2;
}

.breadcrumb-item-end .breadcrumb-content {
  @apply bg-pf-primary text-pf-white;
}

.breadcrumb-item-end .breadcrumb-separator {
  @apply bg-pf-primary;
}

.breadcrumb-item-end .breadcrumb-separator-right {
  @apply bg-pf-white;
}