.input {
    @apply bg-transparent px-1 mr-4 py-1 flex flex-grow font-normal transition-all duration-300;
    resize: none;
    overflow-y: auto;
    height: auto;
    min-height: 1.5em;
    max-height: 10em;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.input:active,
.input:focus {
    @apply bg-pf-gray-T3;
    box-shadow: none !important;
}

.input-sm {
    @apply !min-h-[1.5rem] !max-h-[4rem] font-normal;
    line-height: 1.25;   
}

.input-sm:active {
    @apply bg-pf-gray-T3;
}

.input-md {
    @apply !min-h-[1.75rem] !max-h-[6rem] font-normal;
    line-height: 1.5;   
}

.input-md:active {
    @apply bg-pf-gray-T3;
}

.input-lg {
    @apply !min-h-[2rem] !max-h-[8rem] font-normal;
    line-height: 1.75;   
}

.input-lg:active {
    @apply bg-pf-gray-T3;
}

.input-xl {
    @apply !min-h-[2.5rem] !max-h-[10rem] font-normal;
    line-height: 2;   
}

.input-xl:active {
    @apply bg-pf-gray-T3;
}