/* Base text size */
.responsive-text {
  @apply text-xs leading-4;
}

.responsive-text-minus {
  @apply text-[0.625rem] leading-3;
}

.responsive-text-plus {
  @apply text-sm leading-5;
}

/* Responsive text sizes */
@screen h-sm {
  .responsive-text {
    @apply text-sm leading-5;
  }

  .responsive-text-minus {
    @apply text-xs leading-4;
  }

  .responsive-text-plus {
    @apply text-base leading-6;
  }
}

@screen h-md {
  .responsive-text {
    @apply text-base leading-6;
  }

  .responsive-text-minus {
    @apply text-sm leading-5;
  }

  .responsive-text-plus {
    @apply text-lg leading-7;
  }
}

/* Base icon size */
.responsive-icon {
  @apply w-5 h-5;
}

/* Responsive icon sizes */
@screen h-sm {
  .responsive-icon {
    @apply w-6 h-6;
  }
}

@screen h-md {
  .responsive-icon {
    @apply w-7 h-7;
  }
}

@screen h-lg {
  .responsive-icon {
    @apply w-8 h-8;
  }
}

@screen h-xl {
  .responsive-icon {
    @apply w-8 h-8;
  }
}