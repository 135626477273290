@keyframes fadeOutRightToLeft {
    0% {
        opacity: 1;
        background: linear-gradient(to right, theme('colors.pf-emerald.T3'), theme('colors.pf-emerald.T3'));
    }

    100% {
        opacity: 0;
        background: linear-gradient(to right, theme('colors.pf-emerald.T3'), theme('colors.pf-white'));
    }
}

.animate-fade {
    animation: fadeOutRightToLeft 5s forwards;
}

.added-message {
    background-color: theme('colors.pf-emerald.T3');
    transition: background 5s;
}

.card {
    @apply transition-all duration-300 bg-pf-white dark:bg-pf-gray-S1 border border-pf-gray-S2 dark:border-pf-gray rounded-2xl p-4 relative w-64 h-36 flex flex-col justify-between;
}

.card-clickable {
    @apply hover:bg-pf-white hover:border-[3px] hover:border-pf-swell cursor-pointer;
}

.card-delete-button {
    @apply absolute top-2 right-3 text-pf-gray hover:text-pf-gray-S1;
}

.card-delete-icon {
    @apply text-pf-gray;
}

.card-content {
    @apply transition-all duration-300 flex flex-col space-y-2;
}

.card-title {
    @apply font-semibold text-pf-black dark:text-pf-white truncate;
}

.card-project {
    @apply bg-pf-white rounded-md flex items-center;
}

.card-project-title {
    @apply flex flex-row justify-start items-center text-pf-emerald-S2 text-sm font-bold p-2 rounded-md bg-pf-emerald-T3 dark:text-pf-emerald truncate flex-grow;
}

.card-project-indicator {
    @apply flex items-center;
}

.card-project-dot {
    @apply w-2 h-2 bg-pf-emerald rounded-full mr-2;
}

.card-email {
    @apply text-pf-gray-S1 text-sm truncate;
}

.card-added-message {
    @apply w-full text-sm flex items-center bg-pf-emerald-T3 text-pf-emerald-S2 p-2 rounded-md added-message animate-fade;
}

.card-added-icon {
    @apply w-4 h-4 mr-2 text-pf-emerald;
}
