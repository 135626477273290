.button {
    @apply px-8 py-4 text-white flex items-center justify-center;
}

/* Primary Button */
.button--primary {
    @apply bg-pf-primary text-white border-2 border-pf-primary-S2 border-b-[6px] rounded-2xl transition-all duration-200 font-button py-[14px];
}

.button--primary:not(:disabled):hover {
    @apply bg-pf-primary-S1 bg-opacity-90 transition-all duration-200 mt-1 border-b-4 ease-in py-[8px];
}

.button--primary:not(:disabled):active {
    @apply bg-pf-primary-S1 border-b-2 transform translate-y-1 mt-1 transition-all duration-100 ease-in py-[12px];
}

.button--primary:disabled {
    @apply bg-pf-gray-T2 text-pf-white border-2 cursor-not-allowed border-pf-gray-T1 py-[14px];
}

/* Secondary Button */
.button--secondary {
    @apply bg-white text-pf-primary border border-pf-primary rounded-2xl transition-all duration-200 font-button;
}

.button--secondary:not(:disabled):hover {
    @apply bg-pf-primary-T3 text-pf-primary-S1 transition-all duration-200 ease-in;
}

.button--secondary:not(:disabled):active {
    @apply bg-pf-primary-T2 text-pf-primary-S2 transform translate-y-1 transition-all duration-100 ease-in;
}

.button--secondary:disabled {
    @apply bg-pf-gray-T3 text-pf-gray-T1 border-pf-gray-T1 cursor-not-allowed;
}

/* Tertiary Button */
.button--tertiary {
    @apply bg-white text-pf-black border border-pf-gray-T2 rounded-2xl transition-all duration-200 font-button;
}

.button--tertiary:not(:disabled):active {
    @apply bg-pf-primary-T2 text-pf-primary-S2 transform translate-y-1 transition-all duration-100 ease-in;
}

.button--tertiary:disabled {
    @apply bg-pf-gray-T3 text-pf-gray-T1 border-pf-gray-T1 cursor-not-allowed;
}

/* Error Button */
.button--error {
    @apply bg-pf-berry text-white border-2 border-pf-berry-S2 rounded-2xl transition-all duration-200 font-button py-[14px];
}

.button--error:not(:disabled):hover {
    @apply bg-pf-berry-S1 bg-opacity-90 ease-in;
}

.button--error:not(:disabled):active {
    @apply bg-pf-berry-S1 border-b-2 transform  transition-all duration-100 ease-in;
}

.button--error:disabled {
    @apply bg-pf-gray-T2 text-pf-white border-2 cursor-not-allowed border-pf-gray-T1
}

.button--error:disabled {
    @apply bg-pf-gray-T2 text-pf-white border-2 cursor-not-allowed border-pf-gray-T1;
}

/* Size-specific styles */
.button--xs {
    @apply px-2 py-1 rounded-xl border-b-[5px];
}

.button--xs:not(:disabled):hover {
    @apply py-[3px] border-b-[3px];
}

.button--sm {
    @apply px-3 py-1.5 rounded-xl;
}

.button--sm:not(:disabled):hover {
    @apply py-[5px];
}

.button--md {
    @apply px-4 py-2;
}

.button--lg {
    @apply px-6 py-3;
}

.button--xl {
    @apply px-8 py-4 rounded-2xl border-b-[8px];
}

.button--xl:not(:disabled):hover {
    @apply py-[14px] border-b-[6px] mt-[6px];
}

/* Primary button size-specific styles */
.button--primary.button--xs {
    @apply px-2 py-[2px] rounded-xl border-b-[5px];
}

.button--primary.button--xs:not(:disabled):hover {
    @apply py-[1px] border-b-[3px];
}

.button--primary.button--sm {
    @apply px-3 py-[4px] rounded-xl;
}

.button--primary.button--sm:not(:disabled):hover {
    @apply py-[3px];
}

.button--primary.button--md {
    @apply px-4 py-[6px];
}

.button--primary.button--lg {
    @apply px-6 py-[10px];
}

.button--primary.button--xl {
    @apply px-8 py-[14px] rounded-2xl border-b-[8px];
}

.button--primary.button--xl:not(:disabled):hover {
    @apply py-[12px] border-b-[6px] mt-[6px];
}

/* Loading state */
.button--loading {
    @apply opacity-75 cursor-wait;
}

.button--loading .button__text {
    @apply invisible;
}

.button__spinner {
    @apply absolute inline-flex items-center justify-center;
}

.button__spinner svg {
    @apply animate-spin h-5 w-5;
}

/* Icon positioning */
.button--icon-left {
    @apply flex-row-reverse;
}

.button--icon-right {
    @apply flex-row;
}

.button__icon {
    @apply mr-2;
}

.button--icon-right .button__icon {
    @apply mr-0 ml-2;
}

/* Responsive text styles */
.button {
    @layer responsive-text;
}