.dropdown {
  @apply relative inline-block w-full;
}

.dropdown-header {
  @apply flex items-center text-pf-black justify-between w-full mb-1 px-2 py-1 bg-pf-gray-T3 border-2 border-pf-gray border-b-[6px] rounded-xl cursor-pointer transition-all duration-200 font-button;
  min-height: 48px; /* Ensure consistent height with or without image */
}

.dropdown-header:hover,
.dropdown-header.active {
  @apply bg-pf-gray-T2 mb-[2px] mt-1 border-b-4;
}

.dropdown-header.active {
  @apply bg-pf-gray-T2;
}

.dropdown-chevron {
  @apply ml-2 text-pf-black transition-transform duration-200;
}

.dropdown-chevron.open {
  @apply transform rotate-180;
}

.dropdown-options {
  @apply flex flex-col absolute z-10 w-full mt-1 bg-pf-white border border-pf-gray-T2 rounded-md shadow-lg max-h-[calc(100vh-80px)] overflow-x-hidden overflow-auto;
}

.dropdown-options ul {
  @apply w-full p-0 m-0 list-none;
}

.dropdown-search {
  @apply sticky top-0 bg-pf-white p-2 border-b border-pf-gray z-10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-search input {
  @apply w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-pf-gray-S1 text-pf-black;
}

.dropdown-select-all {
  @apply flex items-center px-4 py-2 cursor-pointer hover:bg-pf-gray-T3 text-pf-black;
}

.dropdown-select-all input {
  @apply mr-2;
}

.dropdown-option {
  @apply w-full px-2 py-2 cursor-pointer transition-colors duration-200 text-pf-black;
  display: block; /* Ensure each option is a block-level element */
}

.dropdown-option-content {
  @apply flex items-center w-full;
}

.dropdown-checkbox {
  @apply mr-2 mt-1 flex-shrink-0;
}

.dropdown-option-image {
  @apply w-8 h-8 mr-2 rounded-sm flex-shrink-0;
}

.dropdown-option-label {
  @apply flex-grow;
  word-break: break-word; /* Allow long words to break and wrap */
}

.dropdown-option:hover {
  @apply bg-pf-gray-T3 w-full;
}

.dropdown-option.selected {
  @apply bg-pf-gray-T2 text-pf-black w-full;
}

.dropdown-option input {
  @apply mr-2;
}

.custom-dropdown-class .dropdown-header {
  @apply bg-pf-gray-T3 text-pf-black border-pf-gray;
}

.custom-dropdown-class .dropdown-options {
  @apply bg-pf-gray-T3 border-pf-gray;
}

.custom-dropdown-class .dropdown-option:hover {
  @apply bg-pf-gray text-pf-black;
}

.custom-dropdown-class .dropdown-option.selected {
  @apply bg-pf-gray text-pf-black;
}

.dropdown-selected-image {
  @apply w-8 h-8 mr-3 object-cover rounded-sm;
}

.dropdown-selected-label {
  @apply flex-grow truncate pr-2;
}

.dropdown-placeholder {
  @apply text-pf-gray pr-2;
}

.dropdown-arrow {
  @apply ml-[-8px] flex-shrink-0 transition-transform duration-200;
}

.dropdown-arrow.open {
  @apply transform rotate-180;
}

/* Responsive sizes */
.dropdown--sm .dropdown-header {
  @apply text-sm py-1 px-2;
  min-height: 36px;
}

.dropdown--md .dropdown-header {
  @apply text-base py-2 px-3;
  min-height: 42px;
}

.dropdown--lg .dropdown-header {
  @apply text-lg py-3 px-4;
  min-height: 48px;
}

.dropdown--sm .dropdown-option {
  @apply text-sm py-1 px-2;
}

.dropdown--md .dropdown-option {
  @apply text-base py-2 px-3;
}

.dropdown--lg .dropdown-option {
  @apply text-lg py-3 px-4;
}

.dropdown--sm .dropdown-option-image,
.dropdown--sm .dropdown-selected-image {
  @apply w-6 h-6;
}

.dropdown--md .dropdown-option-image,
.dropdown--md .dropdown-selected-image {
  @apply w-8 h-8;
}

.dropdown--lg .dropdown-option-image,
.dropdown--lg .dropdown-selected-image {
  @apply w-10 h-10;
}

.dropdown--sm .dropdown-arrow {
  @apply w-4 h-4;
}

.dropdown--md .dropdown-arrow {
  @apply w-5 h-5;
}

.dropdown--lg .dropdown-arrow {
  @apply w-6 h-6;
}
