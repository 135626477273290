.image {
    @apply transition-all duration-300 border-[6px] m-4 border-white rounded-lg shadow-md bg-pf-gray-T2 h-[calc(100%-24px)] max-w-[calc(100%-24px)] object-cover object-center transition-all duration-200;
}

.image:hover {
    @apply transition-all duration-300 border-pf-swell-S1;
}

.image-placeholder {
    @apply border-[6px] m-4 border-white rounded-lg shadow-md bg-pf-gray-T2 h-[calc(100%-24px)] max-w-[calc(100%-24px)] object-cover object-center transition-all duration-200;
}

.image-placeholder:hover {
    @apply border-pf-swell-S1;
    transform: rotate(var(--random-rotation));
}

.image-container {
    @apply relative w-full h-full overflow-hidden;
}

.image-placeholder-icon {
    @apply absolute inset-0 flex items-center justify-center pointer-events-none;
}