.icon-button {
  @apply rounded-xl transition-all duration-200 flex items-center justify-center;
}

.icon-button:not(.icon-button--with-text) {
  @apply w-12 h-12;
}

.icon-button--with-text {
  @apply px-3 py-2;
}

/* Primary IconButton */
.icon-button--primary {
  @apply bg-pf-primary text-pf-white border-2 border-pf-primary-S2 border-b-[6px] rounded-xl;
}
.icon-button--primary:not(:disabled):hover {
  @apply bg-pf-primary-S1 bg-opacity-90 border-b-4 ease-in;
}

.icon-button--primary:not(:disabled):hover:not(.icon-button--with-text) {
  @apply pt-1 pb-1;
}

.icon-button--primary:not(:disabled):hover.icon-button--with-text {
  @apply py-2 mt-[2px];
}

.icon-button--primary:not(:disabled):active {
  @apply bg-pf-primary-S1 border-b-2 transform translate-y-[2px] pt-1 pb-1 transition-all duration-100 ease-in;
}

.icon-button--primary:disabled {
  @apply bg-pf-gray-T2 text-pf-black border-2 cursor-not-allowed border-pf-gray-T1;
}

/* Secondary IconButton */
.icon-button--secondary {
  @apply bg-pf-white text-pf-primary border-2 border-pf-primary rounded-xl;
}

.icon-button--secondary:not(:disabled):hover {
  @apply bg-pf-primary-T3 text-pf-primary-S1 transition-all duration-200 ease-in;
}

.icon-button--secondary:not(:disabled):active {
  @apply bg-pf-primary-T2 text-pf-primary-S2 transform translate-y-1 transition-all duration-100 ease-in;
}

.icon-button--secondary:disabled {
  @apply bg-pf-gray-T3 text-pf-gray-T1 border-pf-gray-T1 cursor-not-allowed;
}

/* Tertiary IconButton */
.icon-button--tertiary {
  @apply bg-white text-pf-gray border border-pf-gray-T2 rounded-xl;
}

.icon-button--tertiary:not(:disabled):active {
  @apply text-pf-sky-S2 transition-all duration-100 ease-in;
}

.icon-button--tertiary:disabled {
  @apply text-pf-gray-T1 border-pf-gray-T3 cursor-not-allowed;
}

/* Borderless IconButton */
.icon-button--borderless {
  @apply bg-transparent border-none text-pf-black border-0;
}

.icon-button--borderless.icon-button--xs {
  @apply m-[-4px];
}

.icon-button--borderless.icon-button--sm {
  @apply m-[-5px];
}

.icon-button--borderless.icon-button--md {
  @apply m-[-6px];
}

.icon-button--borderless.icon-button--lg {
  @apply m-[-5px];
}

.icon-button--borderless.icon-button--xl {
  @apply m-[-6px];
}

.icon-button--borderless:not(:disabled):hover {
  @apply text-pf-primary-S1 transition-all duration-200 ease-in;
}

.icon-button--borderless:not(:disabled):active {
  @apply text-pf-primary-S2 transition-all duration-100 ease-in;
}

.icon-button--borderless:disabled {
  @apply text-pf-gray-T1 cursor-not-allowed;
}

/* Rounded IconButton */
.icon-button--rounded {
  @apply rounded-full bg-pf-primary text-pf-white border-2 border-pf-primary-S2 border-b-[6px];
}

.icon-button--rounded:not(:disabled):hover {
  @apply bg-pf-primary-S1 bg-opacity-90 pt-1 pb-1 border-b-4 ease-in;
}

.icon-button--rounded:not(:disabled):active {
  @apply bg-pf-primary-S1 border-b-2 transform translate-y-[2px] pt-1 pb-1 transition-all duration-100 ease-in;
}

.icon-button--rounded:not(:disabled):hover.icon-button--with-text {
  @apply py-2 mt-[2px];
}

.icon-button--rounded:disabled {
  @apply bg-pf-gray-T2 text-pf-white border-2 cursor-not-allowed border-pf-gray-T1;
}

/* New styles for text positioning */
.icon-button--with-text {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.icon-button__text {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.icon-button__text--left {
  order: -1;
}

.icon-button__text--right {
  order: 1;
}

/* Size-specific styles */
.icon-button--xs:not(.icon-button--with-text) {
  @apply rounded-md border-b-[5px] hover:border-b-[3px] w-6 h-6;
}

.icon-button--sm:not(.icon-button--with-text) {
  @apply rounded-lg w-8 h-8;
}

.icon-button--md:not(.icon-button--with-text) {
  @apply w-10 h-10;
}

.icon-button--lg:not(.icon-button--with-text) {
  @apply w-12 h-12;
}

.icon-button--xl:not(.icon-button--with-text) {
  @apply w-14 h-14;
}

.icon-button--xs.icon-button--with-text {
  @apply rounded-md px-1 py-0.5 hover:py-[2px] transition-all duration-100 ease-in !important;
}

.icon-button--sm.icon-button--with-text {
  @apply px-2 py-1 hover:py-[4px] transition-all duration-100 ease-in !important;
}

.icon-button--md.icon-button--with-text {
  @apply px-3 py-2;
}

.icon-button--lg.icon-button--with-text {
  @apply px-4 py-2;
}

.icon-button--xl.icon-button--with-text {
  @apply px-5 py-3 rounded-2xl hover:py-[12px] border-b-[8px] hover:border-b-[6px] transition-all duration-200 ease-in !important;
}

/* Update text sizes */
.icon-button--xs .icon-button__text {
  @apply text-[10px];
}

.icon-button--xs.icon-button--with-text {
  @apply gap-[0.25rem];
}

.icon-button--sm .icon-button__text {
  @apply text-[12px];
}

.icon-button--md .icon-button__text {
  @apply text-[14px];
}

.icon-button--lg .icon-button__text {
  @apply text-[16px];
}

.icon-button--xl .icon-button__text {
  @apply text-[20px];
}